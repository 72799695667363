import React from "react";
import Prize from "../../assets/svg/8-apdovanojimai.svg";
import "./Apdovanojimai.scss";

function Apdovanojimai() {
    return (
      <div className="prize">
        <div className="prizeCon">
          <div className="prizeText">
            <h3>KOKIE APDOVANOJIMAI LAUKIA DALYVIŲ?</h3>
            <p id="body">
            Europos egzamino prizinis fondas atsitiktine tvarka bus išdalintas 100-ui individualių dalyvių, teisingai atsakiusių į visus egzamino klausimus. Europos egzamino prizinį fondą sudaro: kuprinės, stalo žaidimai „Eureka“, gertuvės, skėčiai, kepuraitės ir kt. <br />
            <br />
            Prizai mokykloms – tai pokalbiai mokykloje su Lietuvos žvaigždėmis. Pokalbį su žvaigžde laimės dvi mokyklos: masiškiausiai Europos egzamine dalyvavusi mokykla ir viena atsitiktine tvarka išrinkta mokykla.
            </p>
          </div>
          <div className="prizeImg">
            <img src={Prize} alt="Prizų iliustracija"/>
          </div>
        </div>
      </div>
    );
}

export default Apdovanojimai;
    