import React from "react";
import KAS from "../../assets/svg/2-Kas-tai.svg";
import KADA from "../../assets/svg/3-kada.svg";
import GALI from "../../assets/svg/4-Kas-gali-dalyvauti.svg";
import GALIwhite from "../../assets/images/gali-white.png";
import KAIP from "../../assets/svg/5-Kaip-vyks-egzaminas.svg";
import KAIPblue from "../../assets/images/kaip-blue.png";
import Prize from "../../assets/svg/8-apdovanojimai.svg";
import "./Info.scss";

function Info() {
    return (
        <div className="info" id="target1">
            <div className="infoMain">
                <h2> APIE EUROPOS EGZAMINĄ</h2>
                <div className="infoTop">
                    <div className="infoMelynas" id="Kas">
                        <div className="infoTitle1">
                            <img src={KAS} alt="Kompiuterio iliustracija"/>
                            <h3>KAS TAI?</h3>
                        </div>
                        <p id="body">
                        Europos egzaminas – tai trumpa, nuotaikinga ir įdomi žinių apie Europos Sąjungą viktorina, skirta paminėti Europos dieną, pasitikrinti žinias arba sužinoti ką nors naujo!
                        <br /> <br />
                        Šiais metais į Europos egzaminą kviečiame šūkiu „Išspręsk, o tada švęsk!“, kadangi švęsti tikrai turime ką: 2024 m. sukanka 20 metų, kai Lietuva prisijungė prie Europos Sąjungos, birželio 9 d. eisime balsuoti Europos Parlamento rinkimuose ir, kaip ir kasmet, gegužės 9 d. minėsime Europos dieną!
                        <br /> <br />
                        Europos egzaminą organizuoja Europos Komisijos atstovybė Lietuvoje kartu su Lietuvos nacionaliniu radiju ir televizija. <br/>
                        Egzamino globėjas – Lietuvos Respublikos Prezidentas Gitanas Nausėda. <br />
                        Egzamino partneriai: Lietuvos Respublikos švietimo, mokslo ir sporto ministerija, Lietuvos Respublikos užsienio reikalų ministerija, EUROPE DIRECT centrai ir Jaunimo Europos komanda.
                        <br />
                        </p>
                    </div>
                    <div className="infoBaltas" id="Kada">
                        <div className="infoTitle2">
                            <img src={KADA} alt="Laikrodžio iliustracija"/>
                            <h3>KADA?</h3>
                        </div>
                        <p id="body">
                        Europos egzaminas vyks internetu gegužės 9 d. 8.00–23.59 val. portale <a href="https://europosegzaminas.lrt.lt/" target="_blank" rel="noreferrer"> europosegzaminas.lrt.lt </a>
                        </p>
                    </div>
                </div>
                <div className="infoBot">
                    <div className="infoBaltas" id="Gali">
                        <div className="infoTitle2">
                            <img src={GALI} id="galiBlue" alt="Klaustuko iliustracija - mėlyna"/>
                            <img src={GALIwhite} id="galiWhite"  alt="Klaustuko iliustracija - balta"/>
                            <h3>KAS GALI DALYVAUTI?</h3>
                        </div>
                            <p id="body">
                            Dalyvauti gali visi norintys – nesvarbu, ar esi moksleivis, ar jau seniai baigei mokyklą – išspręsk egzamino klausimus, o tada švęsk Europos dieną!
                            </p>
                    </div>
                    <div className="infoMelynas" id="Kaip">
                        <div className="infoTitle1" id="ByMantasSimonis">
                            <img src={KAIP} id="kaipWhite" alt="Rašiklio iliustracija - balta"/>
                            <img src={KAIPblue} id="kaipBlue" alt="Rašiklio iliustracija - mėlyna"/>
                            <h3>KAIP VYKS EGZAMINAS?</h3>
                        </div>
                        <p id="body">
                        Europos egzaminą sudaro 10 klausimų su pasirenkamais atsakymų variantais apie Europos Sąjungą. 
                        <br /> <br />
                        Į klausimus reikia atsakyti per kuo trumpesnį laiką. Maksimalus laikas atsakymui pasirinkti – 1 minutė. Kitaip tariant, egzaminas netruks ilgiau nei 10 min. 

                        </p>
                    </div>
                </div>
                <div className="prizeCon">
                    <div className="prizeText">
                        <h3>KOKIE APDOVANOJIMAI LAUKIA DALYVIŲ?</h3>
                        <p id="body">
                        Europos egzamino prizinis fondas atsitiktine tvarka bus išdalintas 100-ui individualių dalyvių, teisingai atsakiusių į visus egzamino klausimus. Europos egzamino prizinį fondą sudaro: kuprinės, stalo žaidimai „Eureka“, gertuvės, skėčiai, kepuraitės ir kt. <br />
                        <br />
                        Prizai mokykloms – tai pokalbiai mokykloje su Lietuvos žvaigždėmis. Pokalbį su žvaigžde laimės dvi mokyklos: masiškiausiai Europos egzamine dalyvavusi mokykla ir viena atsitiktine tvarka išrinkta mokykla.
                        </p>
                    </div>
                    <div className="prizeImg">
                        <img src={Prize} alt="Prizų iliustracija"/>
                    </div>
                </div>
            </div>
        </div>
    );        
}
export default Info;