import React from "react";
import { Link } from "gatsby";
import isStarted from "../../utils/isStarted";
import isEnded from "../../utils/isEnded";
import { RaisedButton } from "../../Components/Buttons";
import Clock from "../Clock";
import "./Countdown.scss";

let deadline = "2024-05-09 04:01:00.000Z";

async function logTime() {
    const response = await fetch("https://timeapi.io/api/Time/current/zone?timeZone=Europe/Vilnius");
    const movies = await response.json();
    console.log(movies);
  }

const Countdown = () => {
    return (
        <div className="countdown">
            {!isStarted() && 
            <div className="countdownContainer">
                <div className="countdown-title">
                    <h3>
                        IKI EUROPOS EGZAMINO LIKO:
                    </h3>
                </div>
                <Clock deadline={deadline} />
            </div>
            }
            {isStarted() && !isEnded() && (
                <div className="countdownStart">
                    <Link to="/egzaminas/registration">
                        <RaisedButton> Laikyk Europos egzaminą </RaisedButton>
                    </Link>
                </div>
            )
            }
        </div>
    );
}
export default Countdown;