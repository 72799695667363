import React, { useEffect, useState } from "react";
import Dots from "../../assets/svg/Dots.svg";
import "../main_page/Countdown.scss";

const Clock = ({ deadline }) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  const leading0 = (num) => {
    return num < 10 ? "0" + num : num;
  };

  const getTimeUntil = (deadline) => {
    const time = Date.parse(deadline) - Date.parse(new Date());
    if (time < 0) {
      setDays(0);
      setHours(0);
      setMinutes(0);
      
    } else {
      setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
      setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
      setMinutes(Math.floor((time / 1000 / 60) % 60));
    }
  };

  useEffect(() => {
    getTimeUntil(deadline);
    setInterval(() => getTimeUntil(deadline), 15000);

    return () => getTimeUntil(deadline);
  }, [deadline]);

  return (
    <div className="countdown-timer">
      <div className="countdown-timer-border">
          <h2>{leading0(days)}</h2>
          <p id="body">DIENOS</p>
          {/*<p id="body">DIENOS</p>*/}
      </div>
      <img src={Dots} alt="laiką skiriantis taškai"/>
      <div className="countdown-timer-border">
          <h2>{leading0(hours)}</h2>
          <p id="body">VALANDOS</p>
          {/*<p id="body">VALANDOS</p>*/}
      </div>
      <img src={Dots} alt="laiką skiriantis taškai"/>
      <div className="countdown-timer-border">
          <h2>{leading0(minutes)}</h2>
          <p id="body">MINUTĖS</p>
          {/*<p id="body">MINUTĖS</p>*/}
      </div>
    </div>
  );
};

export default Clock;
