import React from "react";
import Layout from "../Components/Layout";
//import Header from "../Components/Header";
import Home from "../Components/main_page/Home";
import Ruoskis from "../Components/main_page/Ruoskis";
import Ambasadorius from "../Components/main_page/Ambasadorius";
import Apdovanojimai from "../Components/main_page/Apdovanojimai";
import Info from "../Components/main_page/Info";
import Countdown from "../Components/main_page/Countdown";

const Pagrindinis = () => {
  return (
    <Layout>
    <div className="App">
        {/*<Header />*/} 
        <Home />
        <Countdown />
        <Info />
        <Ruoskis/>
        <Ambasadorius />
        <Apdovanojimai id="prizeR" />
    </div>
    </Layout>
  );
}

export default Pagrindinis;